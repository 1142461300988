body {
  /* font-family: 'interstate_lightregular' !important; */
}

html {
  overflow-y: scroll;
}

.wms-container .app_box {
  justify-content: center;
}

.wms-container {
  margin-top: 24px;
  position: relative;
}

.wms-container .app-mask {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.wms-container .app_box img {
  height: 60%;
}

.wms-container .buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.wms-container .buttons-wrapper .isHide {
  visibility: hidden;
}

.wms-container .free-delivery-fixed {
  width: 80px;
  height: 80px;
  right: 0;
  top: 40%;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-size: 100%;
  background-image: url('./assets/img/alert.png');
  background-repeat: no-repeat;
  position: fixed;
  z-index: 100;
}

.wms-container .free-delivery-triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 26px 0 26px 25px;
  display: block;
  position: absolute;
  top: 0;
  right: -25px;
  border-color: transparent transparent transparent #00ade9;
}

.wms-container .free-delivery-stripe {
  right: 120px;
  top: 41.8%;
  padding: 15px 22px;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #00ade9;
  color: #fff;
  position: fixed;
  z-index: 9999;
}

.wms-container .app-popup {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  min-height: 150px;
  background: white;
  position: fixed;
  z-index: 9999;
  top: 35%;
  padding: 24px;
  left: 0;
  box-shadow: 1px 1px 23px -9px black;
  border-radius: 9px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.wms-container .cancel-button {
  height: 45px;
  margin-top: 15px;
  background-color: #949494;
  display: block;
  padding: 0px 30px 0px 30px !important;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  border: solid 2px transparent;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
}

.wms-container .cancel-button:hover {
  background-color: #4d4d4d;
  transform: scale(0.85);
}

.wms-container .submit-button,
.app-button {
  height: 45px;
  margin-top: 15px;
  background-color: #0191c1;
  display: block;
  padding: 0px 30px 0px 30px !important;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  border: solid 2px transparent;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
}

.wms-container .submit-button.cancel-button {
  height: 45px;
  margin-top: 15px;
  display: block;
  padding: 0px 30px 0px 30px !important;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  border: solid 2px transparent;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  background-color: #ababab;
}

.wms-container .hidden-button {
  visibility: hidden;
}

.wms-container .submit-button:hover,
.wms-container .app-button:hover {
  background-color: #004980;
  transform: scale(0.85);
}

.wms-container .buttons-wrapper .add-product-button,
.wms-container .buttons-wrapper .add-to-warehouse-button,
.save-order-button,
.add-employee-button,
.add-group-button {
  height: 45px;
  margin-top: 15px;
  background-color: #0191c1;
  display: block;
  padding: 8px 30px 0px 30px !important;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  border: solid 2px transparent;
  margin-bottom: 20px;
  cursor: pointer;
  transition: 0.2s all;
  -moz-transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
}

.wms-container #add-product-collapse ul {
  padding-left: 0;
}

.wms-container #add-product-collapse li {
  list-style-type: none;
  display: flex;
  align-items: center;
}

.wms-container #add-product-collapse li span {
  margin-left: 24px;
}

.wms-container #add-product-collapse .remove-image {
  color: red;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}

.wms-container .buttons-wrapper .add-product-button:hover,
.wms-container .buttons-wrapper .add-to-warehouse-button:hover {
  background-color: #004980;
  transform: scale(0.85);
}

.wms-container .nav-link {
  border: solid 1px #d0d0d0;
  border-bottom: solid 1px #fff;
  background-color: #fff;
  margin-bottom: -1px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 5px 18px 7px 18px;
  display: inline-block;
  color: #004071;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
}

.wms-container .table tr {
  font-size: 14px;
  cursor: pointer;
}

.wms-container .table tr:hover {
  background-color: #b3b3b3;
  /* color: white; */
}

.wms-container .table .thead-dark th {
  font-weight: 400;
  color: white;
  background-color: #004980;
  border: 2px solid white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: center;
  border-bottom: none;
}

.wms-container .table td,
.table th {
  border: none;
  text-align: center;
  vertical-align: middle;
}

.wms-container table td.order-product-amount-wrapper {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.wms-container .grey-row {
  background: #efefef;
}

td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child {
  text-align: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.wms-container .action-icon {
  height: 25px;
}

.wms-container .tooltpi-info-wrapper {
  display: flex;
  position: absolute;
  background: #d4d4d4;
  min-width: 150px;
  min-height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
}

/* ADD PRODUCT FORM */
.wms-container .wms-collapse {
  margin-bottom: 17px;
}

/* FORM */

.wms-container p {
  font-weight: bold;
  color: #004071;
  font-size: 2.1rem;
  margin-bottom: 20px;
}

.wms-container input,
.wms-container select {
  height: 45px !important;
  font-size: 14px;
  border: 1px solid #dee2e6;
}

.wms-container label {
  color: #004071 !important;
  font-size: 0.775rem !important;
  font-weight: 700 !important;
}

.wms-container .submit-wrapper {
  justify-content: flex-end;
  display: flex;
}

.wms-container .submit-wrapper .cancel-button {
  margin-right: 16px;
}

.wms-container .error-message {
  color: #c51717;
  margin-bottom: 10px;
  font-size: 0.775rem !important;
}

.wms-container .form-order-detail .error-message {
  float: left;
}

.wms-container .order-table {
  margin-top: 24px;
}

.wms-container .icons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wms-container .icons-wrapper div {
  margin-right: 4px;
}

.wms-container .order-details {
  background: #f9f9f9;
  padding: 0px;
}

.wms-container .order-details .add-product-button {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top: 1px solid #23a0ff;
  cursor: pointer;
  background: #23a0ff42;
}

.wms-container .order-details .add-product-button span {
  display: inline-block;
  color: #004071;
  font-size: 0.875rem;
  font-weight: 700;
  cursor: pointer;
}

.wms-container .order-details .new-light-blue-row {
  background: #c2e2fb61;
}

.wms-container .order-details .new-blue-row {
  background: #c2e2fba6;
}

.wms-container .order-details .new-blue-row:hover,
.wms-container .order-details .new-light-blue-row:hover {
  background-color: #a5d6fc !important;
}

.wms-container .order-details .order-new-product-list {
  height: 35px !important;
  border-radius: 0px;
  width: 210px;
}

/* order details */
.wms-container .order-details .order-product-list .single-product {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.wms-container table .has-error {
  border: 2px solid #df3333;
}

.wms-container .order-details .order-product-list-table,
.wms-container .group-order-single-user {
  background-color: #f9f9f9;
  border: 2px solid #23a0ff;
}

.wms-container .order-details .order-product-list-table thead,
.wms-container .order-details .group-order-single-user-products thead {
  background: #004071;
  color: white;
  border-bottom-left-radius: 15px;
}

.wms-container .order-details .order-product-list-table thead th {
  border-bottom: 0;
}

.wms-container .order-details .order-product-list-table tbody tr:hover {
  background-color: #ffffff;
  /* color: white; */
}

.wms-container .order-details .order-product-list-table thead th:first-child {
  border-bottom-left-radius: 15px;
}

.wms-container .order-details .order-product-list-table thead th:last-child {
  border-bottom-right-radius: 15px;
}

.wms-container .input-file {
  display: none;
}

.wms-container .table input {
  height: 35px !important;
  text-align: center;
  font-size: 15px;
}

.wms-container .toast {
  border-radius: 12px;
  height: 64px;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  bottom: 24px;
  right: 24px;
  padding: 24px;
  box-shadow: 1px 4px 8px 2px #a1a1a1;
  opacity: 1;
  position: absolute;
  top: -15px;
  right: 17px;
}

.wms-container .toast.toast-error {
  background: #d1263b;
}

.wms-container .toast.toast-success {
  background: #17b34b;
}

.wms-container .product-img-hidden:hover {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 24px;
  opacity: 1;
  border: 2px solid #959595;
  background: white;
}

.wms-container .product-img-hidden {
  z-index: 10;
  position: absolute;
  left: 11px;
  opacity: 0;
}

/* SPACING */
.wms-container .mt-16 {
  margin-top: 16px;
}

.wms-container .p-relative {
  position: relative;
}

.wms-container .history-table .status-accepted {
  color: green;
  font-weight: bold;
}

.wms-container .history-table .status-inprogress {
  color: orange;
  font-weight: bold;
}

.wms-container .history-table .status-rejected {
  color: #d71d1d;
  font-weight: bold;
}

.wms-container .history-table .action-button-wrapper {
  display: flex;
  justify-content: center;
}

.wms-container .history-table .ued-info-wrapper {
  position: relative;
}

.wms-container .ued-mail-popup {
  position: absolute;
  background: white;
  padding: 8px;
  z-index: 1;
  border: 1px solid black;
  border-radius: 13px;
  left: -145px;
  width: 250px;
  font-size: 12px;
  top: 9px;
  opacity: 0;
}

.wms-container .history-table .ued-mail-popup:hover {
  opacity: 1;
}

.wms-container .cropper-loader-wrapper {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 18px;
}

.app_box {
  display: flex;
  align-items: center;
  height: 230px;
  text-align: center;
}

.app_box img {
  position: relative;
  z-index: 9;
  height: 30%;
  margin: auto;
}

.app_box_appla {
  position: absolute;
  width: calc(100% - 30px);
  top: 97%;
  height: 3%;
  transition: 0.3s all;
  -moz-transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  background-color: #003862;
}

.add-button-wrapper {
  padding-top: 12px;
}

.system-version {
  font-size: 10px;
  color: grey;
}

.is-empty-stock {
  opacity: 0.3;
}

.app-version {
  color: #80808036;
  font-size: 13px;
}

.link-wrapper {
  cursor: pointer;
}

.ued-modal .single-ued-email {
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
}

.ued-modal .single-ued-email input {
  padding-left: 16px;
  padding-right: 16px;
  width: 45%;
}

.ued-modal .single-ued-email .add-ued-email {
  margin-left: 20px;
  cursor: pointer;
  flex: 1;
}

.ued-modal .single-ued-email input.has-error {
  border: 1px solid red;
}

.ued-modal .ued-emails-error-message {
  color: red;
  font-size: 14px;
}

.ued-modal .remove-ued-email-button {
  display: flex;
  margin-left: 16px;
}

.order-form .info-icon {
  width: 20px;
  height: 22px;
}

.tooltip-text {
  background: #0e0e0e;
  position: absolute;
  padding: 8px;
  top: 23px;
  color: white;
  font-weight: 300;
  font-size: 11px;
  border-radius: 10px;
  z-index: 10;
}

.section-info-text {
  background: #30adb9;
  padding-left: 11px;
  font-weight: bold;
  color: white;
}

.order-summary-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #212121ab;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-summary-wrapper .summary-content {
  background: white;
  position: absolute;
  padding: 24px 24px 4px 24px;
}

.order-summary-wrapper .summary-label {
  background: #30adb9;
  text-align: center;
  color: white;
  font-size: 17px;
}

.order-summary-wrapper .summary-data {
  font-size: 14px;
}

.summary-product-wrapper .product-content-label-wrapper div {
  background: #004071;
  text-align: center;
  color: white;
  padding: 4px;
}

.summary-single-product {
  font-size: 12px;
}

.summary-product-wrapper .product-content {
  overflow: auto !important;
  max-height: 200px;
}

.summary-product-wrapper .product-content .summary-product-lp {
  width: 21px;
  display: inline-block;
  margin-left: 10px;
}

.group-order-form-wrapper .group-single-user-info {
  background: #23a0ff17;
  border-bottom: 1px solid #23a0ff99;
}

.group-order-form-wrapper .group-single-user-info span {
  color: #23a0ff;
}

.group-order-form-wrapper .user-info-icon {
  margin-top: -2px;
}

.group-order-form-wrapper .group-order-single-user-action-button-wrapper {
  cursor: pointer;
}

.group-order-form-wrapper .group-single-order-products-wrapper input {
  height: 45px !important;
}

#group-order .green-button {
  background-color: #40a945;
}

#group-order .hidden-section {
  visibility: hidden;
  height: 0 !important;
  margin-top: 0 !important;
}

.group-list-action-wrapper {
  height: 24px;
}

.cropped-image-wrapper {
  position: relative;
}

.cropped-image-remove-button {
  height: 30px;
  width: 30px;
  display: flex;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 25px;
  justify-content: center;
  background: white;
  align-items: center;
  cursor: pointer;
}